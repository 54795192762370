import { FC } from 'react';
import { IInviteGroup } from '../../types/databaseTypes';
import dayjs from 'dayjs';

interface AdminTableProps {
    inviteGroups: IInviteGroup[];
    openViewInviteGroupModal: (id: string) => void;
}

export const AdminTable: FC<AdminTableProps> = ({
    inviteGroups,
    openViewInviteGroupModal,
}) => {
    const calculateGuestCount = (group: IInviteGroup) => {
        let count = 0;

        group.invites.forEach((invitee) => {
            if (invitee.responded) count++;
        });

        return count;
    };

    return (
        <ul role="list" className="divide-y divide-gray-100 font-text">
            {inviteGroups.map((user) => (
                <li key={user.id} className="flex justify-between gap-x-6 py-2">
                    <button
                        className="flex justify-between w-full p-2 hover:border-gray-900 border border-transparent rounded-md"
                        onClick={() => openViewInviteGroupModal(user.id)}
                    >
                        <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto flex flex-col items-start">
                                <p className="text-base font-semibold leading-6 text-gray-900">
                                    {user.inviteGroupName}
                                </p>
                                <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                                    {user.includesBreakfast
                                        ? 'Full Day Guests'
                                        : 'Evening Guests'}
                                </p>
                                <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                                    {user.invites.map((invitee, i, arr) => (
                                        <span key={invitee.id}>
                                            {invitee.name}
                                            {i != arr.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-end">
                            <p className="text-base font-password leading-6 text-gray-900">
                                {calculateGuestCount(user)}
                                <span> / </span>
                                {user.invites.length}
                            </p>

                            <p className="mt-1 text-sm leading-5 text-gray-500 sm:flex sm:flex-row">
                                {dayjs(user.updatedDate).format('d MMM hh:mm')}
                            </p>
                        </div>
                    </button>
                </li>
            ))}
        </ul>
    );
};
