export const StoryDetails = [
    {
        date: '07 / 10 / 2019',
        title: 'First Meeting',
        details:
            'We first met at a dancing class at the University of Nottingham that neither of us were supposed to be at. John asked Megan to dance a waltz with him and the rest is history. Fun fact: the song that was playing as they danced was Beauty and the Beast.',
    },
    {
        date: '09 / 11 / 2019',
        title: 'First Competition',
        details:
            'We competed together as a couple at the University of Leicester Latin and Ballroom Competition. We represented the University of Nottingham where we were both students',
    },
    {
        date: '13 / 11 / 2019',
        title: 'We Start Dating',
        details:
            '13 may be unlucky for some but not for us. We went on a walk around the University of Nottingham Campus grounds where John asked Megan to be his girlfriend as they walked by the lake. Fun fact: John asked Megan at exactly 13:13!',
    },
    {
        date: '23 / 03 / 2020',
        title: 'Covid lockdown',
        details:
            'Unfortunately, the UK went into lockdown so John had a very interesting birthday. We ended up quarantining together which led to some lovely walks with Poppy. John finally got her approval!',
    },
    {
        date: '22 / 08 / 2022',
        title: 'First Holiday',
        details:
            'We went on our first holiday together, just the two of us to Corfu in Greece. Safe to say John got bit by the travel bug, and we have been on 3 more holidays abroad together since.',
    },
    {
        date: '28 / 01 / 2023',
        title: 'Moved in together',
        details:
            'We started renting a flat together in Manchester city centre and made so many new friends during our time there. We also had a taste of city centre living which was more eating out than nights out for us two.',
    },
    {
        date: '28 / 7 / 2023',
        title: 'We Get Engaged!',
        details:
            'Whilst on holiday in Venice, John had arranged for a professional photoshoot where he proposed to Megan. Of course she said yes! ',
    },
    {
        date: '02 / 09 / 2023',
        title: 'Engagement Party',
        details:
            'Our friends and families came to celebrate our engagement at an Italian Summer Themed Engagement Party. We loved sharing our excitement with everyone.',
    },
    {
        date: '28 / 12 / 2023',
        title: '1 Year to go',
        details:
            'John planned a very busy day of visiting our favourite restaurants, drinking spots, ice skating and even tickets to see Hamilton at the Palace Theatre to celebrate 1 year until our wedding in style!',
    },
    {
        date: '04 / 03 / 2024',
        title: 'Megan gets into Medical School',
        details:
            'Megan chases her dream and gets into medical school! She accepted a place at the University of Nottingham so together we prepare for a new chapter away from both of our families.',
    },
    {
        date: '25 / 07 / 2024',
        title: 'Bought our First House together',
        details:
            'We got the keys to our first ever home together in Derby. We are so grateful for all the help and support we received to start our marital home together and cannot wait for all the visitors!',
    },
];
