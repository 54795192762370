import { FC } from 'react';

interface ScheduleCardProps {
    title: string;
    time: string;
}

export const ScheduleCard: FC<ScheduleCardProps> = ({ title, time }) => {
    return (
        <div className="pt-6 pb-6">
            <h2 className="text-4xl leading-8 text-gray-900 sm:text-4xl font-title_cursive">
                {title}
            </h2>
            <p className="text-lg leading-8 text-gray-600 font-text">{time}</p>
        </div>
    );
};
