import { FC } from 'react';

interface StoryCardProps {
    title: string;
    date: string;
    details: string;
}

export const StoryCard: FC<StoryCardProps> = ({ title, date, details }) => {
    return (
        <div className="pt-6 pb-6 space-y-2">
            <h2 className="text-4xl leading-8 text-gray-900 sm:text-4xl font-title_cursive">
                {title}
            </h2>
            <p className="text-lg leading-8 text-gray-600 font-text">{date}</p>
            <p className="text-lg leading-8 text-gray-600 font-text">
                {details}
            </p>
        </div>
    );
};
