import { FC } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { AccommodationCard } from '../../components/Cards/AccommodationCard';
import { AccommodationDetails } from '../../data/AccommodationDetails';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const AccommodationPage: FC = () => {
    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="Places to Stay"
                image={TitleBannerImageOption.DOOR}
            />

            <div className="relative isolate px-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <p className="mt-2 text-xl sm:text-2xl leading-8 text-gray-600 font-subtitle font-bold">
                        Here is a list of suggested nearby accommodations
                    </p>
                    <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-3">
                        {AccommodationDetails.map((hotel) => (
                            <div key={hotel.id}>
                                <AccommodationCard
                                    name={hotel.name}
                                    street={hotel.street}
                                    city={hotel.city}
                                    postcode={hotel.postcode}
                                    distance={hotel.distance}
                                    image={hotel.image}
                                    hrefWebsite={hotel.hrefWebsite}
                                    hrefLocation={hotel.hrefLocation}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
