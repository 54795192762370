import { FC, useContext, useEffect, useState } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { RSVPForm } from '../../components/Forms/RSVPForm/RSVPForm';
import { IInviteGroupPayload } from '../../types/databaseTypes';
import { fetchInviteGroup } from '../../api/fetchInviteGroup';
import { AuthContext } from '../../context/AuthContext';
import { RSVPDetailsCard } from '../../components/Cards/RSVPDetailsCard';
import { respondGuest } from '../../api/respondGuest';
import { isTokenExpired } from '../../lib';
import { useNavigate } from 'react-router-dom';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const RSVPPage: FC = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [inviteGroup, setInviteGroup] = useState<IInviteGroupPayload>();

    const handleLogout = () => {
        auth.authService.setSession('');
        navigate('/login');
    };

    const fetchInviteGroupHandler = async () => {
        if (isTokenExpired(auth.session)) {
            handleLogout();
        } else {
            const response = await fetchInviteGroup(auth.session);
            setInviteGroup(response.response);
        }
    };

    const respondGuestHandler = async (inviteName: string) => {
        if (isTokenExpired(auth.session)) {
            handleLogout();
        } else {
            if (inviteGroup) {
                const index = inviteGroup.invites.findIndex(
                    (invite) => invite.name == inviteName,
                );
                await respondGuest(inviteGroup.invites[index], auth.session);
                fetchInviteGroupHandler();
            }
        }
    };

    const scrollToElementHandler = (elementID: string) => {
        document.getElementById(elementID)?.scrollIntoView();
    };

    useEffect(() => {
        if (!inviteGroup) fetchInviteGroupHandler();
    }, []);

    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="RSVP"
                image={TitleBannerImageOption.SMILE}
                block={true}
            />

            <div className="relative isolate px-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <div className="text-center ">
                        <p className="mt-1 text-lg sm:text-2xl leading-6 text-zinc-800 font-subtitle">
                            Please respond by filling out this form. There is a
                            separate form for every person on your invitation.
                        </p>
                        {inviteGroup?.invites?.map((invite) => (
                            <div key={invite.name}>
                                {invite.responded ? (
                                    <RSVPDetailsCard
                                        inviteName={invite.name}
                                        inviteGroup={inviteGroup}
                                    />
                                ) : (
                                    <RSVPForm
                                        inviteName={invite.name}
                                        inviteGroup={inviteGroup}
                                        respondHandler={respondGuestHandler}
                                        scrollToElementHandler={
                                            scrollToElementHandler
                                        }
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
