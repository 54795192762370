import React, { FC } from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import './CountdownTimer.css';

interface CountdownTimerProps {
    targetDate: string | number | Date;
}

interface DateTimeDisplayProps {
    value: number;
    type: string;
}

const DateTimeDisplay: FC<DateTimeDisplayProps> = ({ value, type }) => {
    return (
        <div className="countdown">
            <p className="text-zinc-700 text-4xl 2xl:text-5xl">{value}</p>
            <span className="font-subtitle text-zinc-700 text-xl 2xl:text-2xl">
                {type}
            </span>
        </div>
    );
};

export const CountdownTimer: FC<CountdownTimerProps> = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    return (
        <div className="counter">
            <DateTimeDisplay value={days} type={'Days'} />
            <p className="text-zinc-700 text-2xl 2xl:text-3xl font-password">
                :
            </p>
            <DateTimeDisplay value={hours} type={'Hours'} />
            <p className="text-zinc-700 text-2xl 2xl:text-3xl font-password">
                :
            </p>
            <DateTimeDisplay value={minutes} type={'Mins'} />
            <p className="text-zinc-700 text-2xl 2xl:text-3xl font-password">
                :
            </p>
            <DateTimeDisplay value={seconds} type={'Seconds'} />
        </div>
    );
};
