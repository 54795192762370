import { FC, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { InferType, boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = object({
    inviteGroupName: string().required(),
    password: string()
        .required()
        .min(8, '8-character minimum length')
        .matches(
            /^(?=.*[a-z])/,
            'Must contain at least one lowercase character',
        )
        .matches(
            /^(?=.*[A-Z])/,
            'Must contain at least one uppercase character',
        )
        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        .matches(
            /^(?=.*[!@#%&?*£$:;+=])/,
            'Must contain at least one special character',
        ),
    weddingBreakfast: boolean().default(false),
});

export type NewInviteGroupFormValues = InferType<typeof formSchema>;

interface NewInviteGroupFormProps {
    onCancel: () => void;
    onCreate: (values: NewInviteGroupFormValues) => void;
}

export const NewInviteGroupForm: FC<NewInviteGroupFormProps> = ({
    onCancel,
    onCreate,
}) => {
    const [checked, setChecked] = useState(false);

    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors },
    } = useForm<NewInviteGroupFormValues>({
        resolver: yupResolver(formSchema),
        // mode: "onChange"
    });
    const onSubmit: SubmitHandler<NewInviteGroupFormValues> = (values) => {
        console.log(values);
        onCreate(values);

        // const date = new Date();
        // const password = values.name; // TODO: Random password generation
        // const formattedDate = date.toLocaleDateString('en-GB');
        // dataset.push({
        //     id: '0',
        //     name: values.name,
        //     password: password,
        //     createdAt: formattedDate,
        //     updatedDate: formattedDate,
        //     includesBreakfast: checked,
        //     invites: [],
        // });
        // closeModal();
    };

    const handleCheckChange = () => {
        setChecked(!checked);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-12 font-text">
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
                        <fieldset>
                            <div className="mt-6 space-y-6">
                                <div className="mb-4">
                                    <label
                                        htmlFor="invite-group-name"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        Name:
                                    </label>
                                    <input
                                        {...register('inviteGroupName')}
                                        id="invite-group-name"
                                        type="text"
                                        placeholder=". . ."
                                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                            errors.inviteGroupName?.message
                                                ? 'border-red-500 border-2'
                                                : 'border-gray-300'
                                        }`}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="password"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        Password:
                                    </label>
                                    <input
                                        {...register('password')}
                                        id="password"
                                        type="text"
                                        placeholder=". . ."
                                        className={`shadow appearance-none font-password border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                            errors.password?.message
                                                ? 'border-red-500 border-2'
                                                : 'border-gray-300'
                                        }`}
                                    />
                                    <p className="text-red-500 pl-2">
                                        {errors.password?.message}
                                    </p>
                                </div>
                                <div className="flex items-center gap-x-3">
                                    <input
                                        {...register('weddingBreakfast')}
                                        id="wedding-breakfast"
                                        type="checkbox"
                                        checked={checked}
                                        onChange={handleCheckChange}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label
                                        htmlFor="wedding-breakfast"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Attending the Wedding Breakfast
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6 font-text">
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={onCancel}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    );
};
