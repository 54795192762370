import { FC, useState, useContext, useMemo, Fragment } from 'react';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { InferType, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
// import HeroImage from '../../assets/Backgrounds/ll_venezia_50762_216.jpg';
// import HeroMobileImage from '../../assets/Backgrounds/ll_venezia_50762_216_Mobile.jpg';
import { config } from '../../cognitoconfig';
import { LoginModes } from '../../types/Enums';

export const LandingPage: FC = () => {
    const auth = useContext(AuthContext);

    const [errorModal, setErrorModal] = useState(false);
    const navigate = useNavigate();

    const formSchema = useMemo(() => {
        return object({
            name: string().required(),
            password: string().required(),
        });
    }, []);

    type FormValues = InferType<typeof formSchema>;

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(formSchema),
    });
    const onSubmit: SubmitHandler<FormValues> = async (values) => {
        const userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId,
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: values.name,
            Password: values.password,
        });

        const cognitoUser = new CognitoUser({
            Username: values.name,
            Pool: userPool,
        });

        console.log(authenticationDetails);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                console.log('Authentication successful!', session);
                auth.authService.setMode(LoginModes.GUEST);
                auth.authService.setSession(session.getIdToken().getJwtToken());
                console.log(values.name + ' is logged in');
                navigate('/home');
            },
            onFailure: (error) => {
                console.error('Authentication failed:', error);
                console.log('Login failed');
                setErrorModal(true);
            },
        });
    };

    const closeErrorModal = () => {
        resetField('name');
        resetField('password');
        setErrorModal(false);
    };

    return (
        <div className="absolute bg-landing-page-mobile sm:bg-landing-page inset-0 z-0 bg-cover bg-center bg-no-repeat overflow-hidden">
            <div className="absolute w-full h-full flex flex-col justify-center items-center z-10 pb-16 sm:pb-40">
                <form
                    className="bg-rose-100 shadow-md rounded-lg max-w-xs sm:max-w-lg px-8 pt-6 pb-8"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-lg sm:text-xl font-bold font-subtitle mb-2"
                            htmlFor="name"
                        >
                            Enter your username
                        </label>
                        <input
                            {...register('name')}
                            className={`shadow appearance-none border rounded w-full sm:w-[300px] text-base sm:text-lg font-text py-2 px-3 sm:mx-6 text-gray-700 leading-tight tracking-wide sm:tracking-wider focus:outline-none focus:shadow-outline ${
                                errors.name?.message
                                    ? 'border-red-500'
                                    : 'border-gray-200'
                            }`}
                            id="name"
                            type="text"
                            placeholder=". . ."
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-lg sm:text-xl font-bold font-subtitle mb-2"
                            htmlFor="password"
                        >
                            Enter your password
                        </label>
                        <input
                            {...register('password')}
                            className={`shadow appearance-none border rounded w-full sm:w-[300px] text-base sm:text-lg font-password py-2 px-3 text-gray-700 leading-tight tracking-wide sm:tracking-wider focus:outline-none focus:shadow-outline ${
                                errors.password?.message
                                    ? 'border-red-500'
                                    : 'border-gray-200'
                            }`}
                            id="password"
                            type="password"
                            placeholder=". . ."
                        />
                    </div>
                    <div className="flex items-center justify-center pt-4">
                        <button
                            className="bg-red-900 hover:bg-red-800 text-white font-bold font-subtitle py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            ENTER
                        </button>
                    </div>
                </form>
            </div>

            <Transition appear show={errorModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeErrorModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                                <p className="block text-gray-700 text-sm font-bold font-text mb-2">
                                    You have entered an invalid username or
                                    password
                                </p>
                                <br />
                                <p className="block text-gray-700 text-sm font-bold font-text mb-2">
                                    Please check your invite for your login
                                    details
                                </p>
                                <br />
                                <button
                                    className="bg-red-900 hover:bg-red-800 text-white font-bold font-subtitle py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={closeErrorModal}
                                >
                                    Try Again
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
