import { FC, useMemo } from 'react';
import { CustomIcon } from '../CustomIcon/CustomIcon';
import { CustomIconEnum } from '../../types/Enums';
import { IInviteGroupPayload } from '../../types/databaseTypes';

interface DownloadEveningGuestsProps {
    inviteData: IInviteGroupPayload[];
}

interface IInviteFilter {
    name: string;
    dietaryRequirements?: string;
    songRecommendation?: string;
}

export const DownloadEveningGuests: FC<DownloadEveningGuestsProps> = ({
    inviteData,
}) => {
    const filterData = () => {
        const filteredInvites: IInviteFilter[] = [];
        inviteData
            .filter((inviteGroup) => {
                return !inviteGroup.includesBreakfast;
            })
            .forEach((group) => {
                group.invites.forEach((invitee) => {
                    if (invitee.responded && invitee.party?.attending)
                        filteredInvites.push({
                            name: invitee.name,
                            dietaryRequirements:
                                '"' + invitee.dietaryRequirements + '"' || ' ',
                            songRecommendation:
                                '"' + invitee.songRecommendation + '"' || ' '
                        });
                });
            });
        return filteredInvites;
    };

    const downloadUrl = useMemo(() => {
        console.log(filterData());
        const blob = new Blob(
            [
                'Name,Dietary Requirements,Song Recommendation\n' +
                    filterData()
                        .map((person) => {
                            return `${person.name},${person.dietaryRequirements},${person.songRecommendation}`;
                        })
                        .join('\n'),
            ],
            {
                type: 'text/plain',
            },
        );
        return window.URL.createObjectURL(blob);
    }, [inviteData]);

    return (
        <a
            href={downloadUrl}
            download="EveningGuests.csv"
            className="inline-block h-6 w-6"
        >
            <CustomIcon icon={CustomIconEnum.DOWNLOAD} color={'black'} />
        </a>
    );
};
