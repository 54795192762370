import { IInvitePayload } from '../types/databaseTypes';

export const respondGuest = async (
    body: IInvitePayload,
    sessionToken?: string,
) => {
    const response = await fetch(
        `https://dqfc07k4y5.execute-api.eu-west-2.amazonaws.com/Staging/respond-guest`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({
                Authorization: sessionToken || '',
                'Content-Type': 'application/json',
            }),
        },
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    await response.json();
};
