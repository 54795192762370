import './RSVPForm.css';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
    FoodOptions,
    FoodChildOptions,
    FoodVeganOptions,
} from '../../../data/FoodOptions';
import { useForm, SubmitHandler } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IInviteGroupPayload } from '../../../types/databaseTypes';
import { CustomIcon } from '../../CustomIcon/CustomIcon';
import { CustomIconEnum } from '../../../types/Enums';
import { newtonsCradle } from 'ldrs';

interface RSVPFormProps {
    inviteGroup: IInviteGroupPayload | undefined;
    inviteName: string;
    respondHandler: (inviteName: string) => void;
    scrollToElementHandler: (elementID: string) => void;
}

export const RSVPForm: FC<RSVPFormProps> = ({
    inviteGroup,
    inviteName,
    respondHandler,
    scrollToElementHandler,
}) => {
    const observerRef = useRef(null);
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // Loop over the entries
            entries.forEach((entry) => {
                // If the element is visible
                if (entry.isIntersecting) {
                    console.log(entry.target);
                    // Add the animation class
                    entry.target.classList.add('fade-entrance');
                }
            });
        });

        if (observerRef.current) observer.observe(observerRef.current);
    }, [observerRef]);

    const formSchema = useMemo(() => {
        return object({
            attending: string().required(),
            ...(inviteGroup?.includesBreakfast
                ? {
                      starter: string().when('attending', {
                          is: 'yes',
                          then: (schema) => schema.required(),
                      }),
                      main: string().when('attending', {
                          is: 'yes',
                          then: (schema) => schema.required(),
                      }),
                      dessert: string().when('attending', {
                          is: 'yes',
                          then: (schema) => schema.required(),
                      }),
                  }
                : {}),
            dietaryRequirements: string(),
            songRecommendation: string(),
        });
    }, [inviteGroup]);

    type FormValues = InferType<typeof formSchema>;

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(formSchema),
        // mode: "onChange"
    });
    const onSubmit: SubmitHandler<FormValues> = (values) => {
        console.log(values);
        if (invite && inviteGroup) {
            invite.responded = true;
            invite.ceremony.attending = values.attending === 'yes';
            invite.party.attending = values.attending === 'yes';
            if (values.attending === 'yes') {
                if (inviteGroup.includesBreakfast) {
                    invite.breakfast!.attending = values.attending === 'yes';
                    invite.breakfast!.starter = values.starter as string;
                    invite.breakfast!.main = values.main as string;
                    invite.breakfast!.dessert = values.dessert as string;
                }
                if (values.dietaryRequirements)
                    invite.dietaryRequirements = values.dietaryRequirements;
                if (values.songRecommendation)
                    invite.songRecommendation = values.songRecommendation;
            }
        }
        setSubmitting(true);
        respondHandler(inviteName);
    };

    const resetFormHandler = () => {
        reset();
        if (invite) scrollToElementHandler(invite.name);
    };

    const watchAttending = watch('attending');

    const invite = inviteGroup?.invites.find((invite) => {
        return invite.name === inviteName;
    });

    const foodOptions = invite?.breakfast?.child
        ? FoodChildOptions
        : invite?.breakfast?.vegan
          ? FoodVeganOptions
          : FoodOptions;

    newtonsCradle.register();

    return (
        <form
            id={invite?.name}
            onSubmit={handleSubmit(onSubmit)}
            className="scroll-m-[100px] opacity-0"
            ref={observerRef}
        >
            <div className="border-2 rounded-2xl border-zinc-400 mt-12 mb-2 bg-christmas bg-cover">
                <div className="w-full h-full faded-background p-6 rounded-2xl">
                    <h2 className="text-3xl sm:text-4xl font-semibold font-title_cursive leading-7 text-gray-900">
                        {invite ? invite?.name : 'Name'}
                    </h2>

                    <div className="mt-6 space-y-2 grid grid-cols-1 gap-x-6 gap-y-8">
                        <fieldset>
                            <legend className="text-lg sm:text-xl font-medium leading-6 text-gray-900 font-subtitle">
                                Attending?
                            </legend>
                            <div className="mt-6 grid grid-cols-2">
                                <div className="flex justify-center gap-x-3">
                                    <label className="block text-sm sm:text-base font-medium leading-6 text-gray-900 font-text">
                                        <input
                                            {...register('attending')}
                                            id="attending-yes"
                                            type="radio"
                                            value="yes"
                                            className="imgButton h-4 w-4 border-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                        />
                                        <CustomIcon
                                            icon={CustomIconEnum.HEART}
                                            color="currentColor"
                                            removePadding={true}
                                            className="heart w-12 h-12 m-auto"
                                        />
                                        Joyfully Accept
                                    </label>
                                </div>
                                <div className="flex justify-center gap-x-3">
                                    <label className="flex flex-col text-sm sm:text-base font-medium leading-6 text-gray-900 font-text">
                                        <input
                                            {...register('attending')}
                                            id="attending-no"
                                            type="radio"
                                            value="no"
                                            className="imgButton h-4 w-4 border-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                        />
                                        {watchAttending == 'no' ? (
                                            <CustomIcon
                                                icon={CustomIconEnum.HEARTBREAK}
                                                color="currentColor"
                                                removePadding={true}
                                                className="heart w-12 h-12 m-auto"
                                            />
                                        ) : (
                                            <CustomIcon
                                                icon={CustomIconEnum.HEART}
                                                color="currentColor"
                                                removePadding={true}
                                                className="heart w-12 h-12 m-auto"
                                            />
                                        )}
                                        Regretfully Decline
                                    </label>
                                </div>
                            </div>
                        </fieldset>

                        {inviteGroup?.includesBreakfast &&
                            watchAttending === 'yes' && (
                                <div className="space-y-6">
                                    <label className="block text-lg sm:text-xl font-medium leading-6 text-gray-900 font-subtitle">
                                        Food Selection :
                                    </label>

                                    <div
                                        className={`${
                                            errors.starter?.message &&
                                            'border border-red-500 rounded-lg'
                                        }`}
                                    >
                                        <label
                                            className={`text-sm sm:text-lg font-bold leading-6 text-gray-900 flex justify-start px-2 font-text ${
                                                errors.starter?.message
                                                    ? 'text-red-500'
                                                    : 'text-gray-900'
                                            }`}
                                        >
                                            Starter course :
                                        </label>
                                        {foodOptions[0].options.map(
                                            (dish, dishIdx) => (
                                                <div
                                                    key={`starter-${dishIdx}`}
                                                    className="flex justify-start items-start mb-2 min-h-[1.5rem] pl-[1rem]"
                                                >
                                                    <input
                                                        className="flex-none h-4 w-4 mt-[0.315rem] sm:mt-[0.4rem] mr-3 border-gray-300 text-red-800 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                                        type="radio"
                                                        value={dish.value}
                                                        id={`starter-${dishIdx}`}
                                                        {...register('starter')}
                                                    />
                                                    <label
                                                        className="text-sm sm:text-lg font-normal leading-6 text-gray-900 flex text-left mt-px font-text hover:cursor-pointer"
                                                        htmlFor={`starter-${dishIdx}`}
                                                    >
                                                        {dish.option}
                                                    </label>
                                                </div>
                                            ),
                                        )}
                                        {/* <p>{errors.starter?.message}</p> */}
                                    </div>

                                    <div
                                        className={`${
                                            errors.main?.message &&
                                            'border border-red-500 rounded-lg'
                                        }`}
                                    >
                                        <label
                                            className={`text-sm sm:text-lg font-bold leading-6 text-gray-900 flex justify-start px-2 font-text ${
                                                errors.main?.message
                                                    ? 'text-red-500'
                                                    : 'text-gray-900'
                                            }`}
                                        >
                                            Main course :
                                        </label>
                                        {foodOptions[1].options.map(
                                            (dish, dishIdx) => (
                                                <div
                                                    key={`main-${dishIdx}`}
                                                    className="flex justify-start items-start mb-2 min-h-[1.5rem] pl-[1rem]"
                                                >
                                                    <input
                                                        className="flex-none h-4 w-4 mt mt-[0.315rem] sm:mt-[0.4rem] mr-3 border-gray-300 text-red-800 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                                        type="radio"
                                                        value={dish.value}
                                                        id={`main-${dishIdx}`}
                                                        {...register('main')}
                                                    />
                                                    <label
                                                        className="text-sm sm:text-lg font-normal leading-6 text-gray-900 flex text-left mt-px font-text hover:cursor-pointer"
                                                        htmlFor={`main-${dishIdx}`}
                                                    >
                                                        {dish.option}
                                                    </label>
                                                </div>
                                            ),
                                        )}
                                        {/* <p>{errors.main?.message}</p> */}
                                    </div>

                                    <div
                                        className={`${
                                            errors.dessert?.message &&
                                            'border border-red-500 rounded-lg'
                                        }`}
                                    >
                                        <label
                                            className={`text-sm sm:text-lg font-bold leading-6 text-gray-900 flex justify-start px-2 font-text ${
                                                errors.dessert?.message
                                                    ? 'text-red-500'
                                                    : 'text-gray-900'
                                            }`}
                                        >
                                            Dessert course :
                                        </label>
                                        {foodOptions[2].options.map(
                                            (dish, dishIdx) => (
                                                <div
                                                    key={`dessert-${dishIdx}`}
                                                    className="flex justify-start items-start mb-2 min-h-[1.5rem] pl-[1rem]"
                                                >
                                                    <input
                                                        className="flex-none h-4 w-4 mt-[0.315rem] sm:mt-[0.4rem] mr-3 border-gray-300 text-red-800 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                                        type="radio"
                                                        value={dish.value}
                                                        id={`dessert-${dishIdx}`}
                                                        {...register('dessert')}
                                                    />
                                                    <label
                                                        className="text-sm sm:text-lg font-normal leading-6 text-gray-900 flex text-left mt-px font-text hover:cursor-pointer"
                                                        htmlFor={`dessert-${dishIdx}`}
                                                    >
                                                        {dish.option}
                                                    </label>
                                                </div>
                                            ),
                                        )}
                                        {/* <p>{errors.dessert?.message}</p> */}
                                    </div>
                                </div>
                            )}

                        {watchAttending === 'yes' && (
                            <div className="col-span-full">
                                <label
                                    htmlFor="diet"
                                    className="block text-lg sm:text-xl font-medium leading-6 text-gray-900 font-subtitle"
                                >
                                    Any Dietary Requirements?
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        {...register('dietaryRequirements')}
                                        id="diet"
                                        // name="diet"
                                        rows={3}
                                        className="block w-full font-text rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-zinc-400 placeholder:text-zinc-500 text-base sm:text-lg sm:leading-6 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                        placeholder="Please tell us about any requirements you may have."
                                    />
                                </div>
                            </div>
                        )}

                        {watchAttending === 'yes' && (
                            <div className="col-span-full">
                                <label
                                    htmlFor="song"
                                    className="block text-lg sm:text-xl font-medium leading-6 text-gray-900 font-subtitle"
                                >
                                    <p className="w-[200px] sm:w-full m-auto">
                                        Please tell us your Song Recommendation
                                    </p>
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register('songRecommendation')}
                                        id="song"
                                        // name="song"
                                        type="text"
                                        className="block w-full font-text rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-zinc-400 placeholder:text-zinc-500 text-base sm:text-lg sm:leading-6 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                        placeholder=". . ."
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {submitting && (
                        <div className="absolute w-full h-full z-20 top-0 left-0 pb-14 px-2 pt-2 content-center">
                            <div className="w-full h-full content-center backdrop-blur rounded-lg">
                                <l-newtons-cradle
                                    size="78"
                                    speed="1.4"
                                    color="#7f1d1d"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-4 mr-4 flex items-center justify-end gap-x-6">
                <button
                    type="reset"
                    className="rounded-md bg-zinc-100 px-4 py-2 text-sm sm:text-base font-semibold font-subtitle text-zinc-800 shadow-sm hover:bg-zinc-0 border border-zinc-400 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                    onClick={resetFormHandler}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-red-900 px-4 py-2 text-sm sm:text-base font-semibold font-subtitle text-white shadow-sm hover:bg-red-800 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                >
                    Respond
                </button>
            </div>
        </form>
    );
};
