export const FoodOptions = [
    {
        course: 'Starter',
        options: [
            {
                option: 'Seasonal leek and potato soup served with crispy croutons',
                value: 'Soup',
            },
            {
                option: 'Chicken liver parfait served with caramelised red onion chutney, toasted brioche, and olive oil powder',
                value: 'Parfait',
            },
        ],
    },
    {
        course: 'Main',
        options: [
            {
                option: 'Poached Scottish salmon with chervil butter sauce',
                value: 'Salmon',
            },
            {
                option: 'Aged beef fillet with red wine sauce',
                value: 'Beef',
            },
        ],
    },
    {
        course: 'Dessert',
        options: [
            {
                option: 'Baked apple and cinnamon syrup sponge served with English vanilla custard',
                value: 'Sponge',
            },
            {
                option: 'Profiterole filled with white chocolate mousse served with a Belgian chocolate sauce',
                value: 'Profiterole',
            },
        ],
    },
];

export const FoodChildOptions = [
    {
        course: 'Starter',
        options: [
            {
                option: 'Chilled melon cocktail',
                value: 'Child Melon',
            },
        ],
    },
    {
        course: 'Main',
        options: [
            {
                option: 'Crispy chicken goujons and chips',
                value: 'Child Chicken',
            },
        ],
    },
    {
        course: 'Dessert',
        options: [
            {
                option: 'Ice Cream',
                value: 'Child Ice Cream',
            },
        ],
    },
];

export const FoodVeganOptions = [
    {
        course: 'Starter',
        options: [
            {
                option: 'Vegan Starter',
                value: 'Vegan Starter',
            },
        ],
    },
    {
        course: 'Main',
        options: [
            {
                option: 'Vegan Main',
                value: 'Vegan Main',
            },
        ],
    },
    {
        course: 'Dessert',
        options: [
            {
                option: 'Vegan Dessert',
                value: 'Vegan Dessert',
            },
        ],
    },
];
