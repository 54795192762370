import { FC, useMemo } from 'react';
import { CustomIcon } from '../CustomIcon/CustomIcon';
import { CustomIconEnum } from '../../types/Enums';
import { IInviteGroupPayload } from '../../types/databaseTypes';

interface DownloadFullGuestsProps {
    inviteData: IInviteGroupPayload[];
}

interface IInviteFilter {
    name: string;
    starter: string;
    main: string;
    dessert: string;
    dietaryRequirements?: string;
    songRecommendation?: string;
}

export const DownloadFullGuests: FC<DownloadFullGuestsProps> = ({
    inviteData,
}) => {
    const filterData = () => {
        const filteredInvites: IInviteFilter[] = [];
        inviteData
            .filter((inviteGroup) => {
                return inviteGroup.includesBreakfast;
            })
            .forEach((group) => {
                group.invites.forEach((invitee) => {
                    if (invitee.responded && invitee.breakfast?.attending)
                        filteredInvites.push({
                            name: invitee.name,
                            starter: invitee.breakfast.starter,
                            main: invitee.breakfast.main,
                            dessert: invitee.breakfast.dessert,
                            dietaryRequirements:
                                '"' + invitee.dietaryRequirements + '"' || ' ',
                            songRecommendation:
                                '"' + invitee.songRecommendation + '"' || ' ',
                        });
                });
            });
        return filteredInvites;
    };

    const downloadUrl = useMemo(() => {
        console.log(filterData());
        const blob = new Blob(
            [
                'Name,Starter,Main,Dessert,Dietary Requirements,Song Recommendation\n' +
                    filterData()
                        .map((person) => {
                            return `${person.name},${person.starter},${person.main},${person.dessert},${person.dietaryRequirements},${person.songRecommendation}`;
                        })
                        .join('\n'),
            ],
            {
                type: 'text/plain',
            },
        );
        return window.URL.createObjectURL(blob);
    }, [inviteData]);

    return (
        <a
            href={downloadUrl}
            download="FullDayGuests.csv"
            className="inline-block h-6 w-6"
        >
            <CustomIcon icon={CustomIconEnum.DOWNLOAD} color={'black'} />
        </a>
    );
};
