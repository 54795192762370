import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { LoginModes } from '../../types/Enums';
import { isTokenExpired } from '../../lib';

export const ProtectedAdminRoute = () => {
    const auth = useContext(AuthContext);

    const tokenExpired = isTokenExpired(auth.session);

    if (auth.mode != LoginModes.ADMIN || tokenExpired) {
        return <Navigate to="/admin" replace />;
    }

    return <Outlet />;
};
