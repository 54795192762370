export const createInviteGroup = async (
    body: {
        inviteGroupName: string;
        password: string;
        includesBreakfast: boolean;
    },
    sessionToken?: string,
) => {
    const response = await fetch(
        `https://dqfc07k4y5.execute-api.eu-west-2.amazonaws.com/Staging/create-invite`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({
                Authorization: sessionToken || '',
                'Content-Type': 'application/json',
            }),
        },
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const json = await response.json();
    return json.inviteGroup;
};
