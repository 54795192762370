import './App.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { SignupPage } from './pages/Signup';
import { SignupConfirmationPage } from './pages/SignupConfirmation';
import { LoginPage } from './pages/Login';
import { AdminPage } from './pages/Admin';
import { HomePage } from './pages/Home';
import { OurStoryPage } from './pages/OurStory';
import { SchedulePage } from './pages/Schedule';
import { WeddingPartyPage } from './pages/WeddingParty';
import { AccommodationPage } from './pages/Accommodation';
import { RegistryPage } from './pages/Registry';
import { FAQPage } from './pages/FAQ';
import { RSVPPage } from './pages/RSVP';
import { LandingPage } from './pages/Landing';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { isDevBuild } from './lib';
import { ProtectedAdminRoute } from './components/ProtectedRoute/ProtectedAdminRoute';

function App() {
    return (
        <main className="App">
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />

                        <Route element={<ProtectedRoute />}>
                            <Route path="/home" element={<HomePage />} />
                            <Route
                                path="/our-story"
                                element={<OurStoryPage />}
                            />
                            <Route
                                path="/schedule"
                                element={<SchedulePage />}
                            />
                            <Route
                                path="/accommodation"
                                element={<AccommodationPage />}
                            />
                            <Route
                                path="/wedding-party"
                                element={<WeddingPartyPage />}
                            />
                            <Route
                                path="/registry"
                                element={<RegistryPage />}
                            />
                            <Route path="/faq" element={<FAQPage />} />

                            <Route path="/rsvp" element={<RSVPPage />} />
                        </Route>

                        <Route path="/admin" element={<LoginPage />} />

                        <Route element={<ProtectedAdminRoute />}>
                            <Route path="/admin-home" element={<AdminPage />} />
                        </Route>

                        {isDevBuild() && (
                            <>
                                <Route
                                    path="/signup"
                                    element={<SignupPage />}
                                />
                                <Route
                                    path="/signup-confirmation"
                                    element={<SignupConfirmationPage />}
                                />
                            </>
                        )}
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </main>
    );
}

export default App;
