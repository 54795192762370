import { FC } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { PartyMemberCard } from '../../components/Cards/PartyMemberCard';
import { WeddingPartyDetails } from '../../data/WeddingPartyDetails';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const WeddingPartyPage: FC = () => {
    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="Wedding Party"
                image={TitleBannerImageOption.TWIRL}
            />

            <div className="relative isolate px-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <div className="mt-6 divide-y-2 divide-zinc-800/20">
                        {WeddingPartyDetails.map((group) => (
                            <div
                                key={group.group}
                                className={`sm:flex sm:flex-row justify-center xl:gap-x-8 ${
                                    group.group == 3 || group.group == 4
                                        ? 'inline-flex flex-col'
                                        : group.group == 6
                                          ? 'flex flex-col items-center'
                                          : 'flex flex-row'
                                }`}
                            >
                                {group.members.map((person) => (
                                    <div key={person.id}>
                                        <PartyMemberCard
                                            name={person.name}
                                            role={person.role}
                                            image={person.image}
                                            wide={person.wide || false}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
