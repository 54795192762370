export enum CustomIconEnum {
    ARRIVE,
    CEREMONY,
    DRINKS_RECEPTION,
    WEDDING_BREAKFAST,
    SPEECHES,
    EVENING_RECEPTION,
    CAKE_CUTTING,
    FIRST_DANCE,
    PARTY,
    EVENING_FOOD,
    LEAVE,
    HEART,
    HEARTBREAK,
    DOWNLOAD,
}

export enum LoginModes {
    UNAUTHORISED = 'UNAUTHORISED',
    ADMIN = 'ADMIN',
    GUEST = 'GUEST',
}

export enum TitleBannerImageOption {
    WINTER,
    VENICE,
    BRIDGE,
    DOOR,
    DISTANCE,
    TWIRL,
    STROLL,
    WALL,
    PROPOSE,
    SMILE,
}

export enum WeddingPartyImageOption {
    MEGAN,
    JOHN,
    FRANCESCA,
    DAVID,
    LUCY,
    ERIN,
    AMELIA,
    ALEX,
    AAKASH,
    JACOB,
    JOSH,
    IONA,
    EVELYN,
    GRAYSON,
    CURLEY,
    MERCER,
    POPPY,
}
