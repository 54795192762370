import { FC } from 'react';

interface AccommodationCardProps {
    name: string;
    street: string;
    city: string;
    postcode: string;
    distance: string;
    image: string;
    hrefWebsite?: string;
    hrefLocation?: string;
}

export const AccommodationCard: FC<AccommodationCardProps> = ({
    name,
    street,
    city,
    postcode,
    distance,
    image,
    hrefWebsite,
    hrefLocation,
}) => {
    return (
        <div className="p-4 bg-white rounded-lg border-2 border-zinc-400">
            <div className="hover:shadow-md">
                <a href={hrefWebsite} target="_blank">
                    <img
                        src={image}
                        className="w-[300px] max-h-[200px] object-contain rounded-lg"
                    />
                    <p className="mt-2 mb-4 text-base leading-8 text-gray-600 font-semibold font-subtitle underline">
                        {name}
                    </p>
                </a>
            </div>

            <div className="hover:shadow-md">
                <a href={hrefLocation} target="_blank">
                    <p className="text-base leading-8 text-blue-800 font-text underline">
                        {street}, {city}, {postcode}
                    </p>
                    <p className="mt-2 text-base leading-8 text-gray-600 font-text">
                        {distance}
                    </p>
                </a>
            </div>
        </div>
    );
};
