export const FAQData = [
    {
        question: 'What is the dress code?',
        answer: 'Our dress code is Formal and Black Tie Optional. \nWe encourage our guests to get into the spirit of Christmas with some glitz and glam. We ask that you please avoid our wedding colours of white/cream/ivory, burgundy and pink.',
    },
    {
        question: 'When do I need to RSVP by?',
        answer: 'Please RSVP using the button by the date specified on your physical invitation.',
    },
    {
        question: 'Where is the ceremony and reception?',
        answer: 'The ceremony and reception are held at separate venues. Please visit our When & Where page for full addresses and links to Google Maps.',
    },
    {
        question: 'Is there parking available?',
        answer: 'Parking is not allowed in the church courtyard however there is parking on the surrounding streets. Space can be limited so make sure to get there in ample time as you may need to walk up the street. \n\nThere is no transport between the ceremony and reception venues however there is plenty of parking at the Saddleworth Hotel with multiple car parks.',
    },
    {
        question: 'What do I do about accommodation?',
        answer: 'There are no rooms available at the hotel as these have been reserved for family members however there are multiple establishments nearby. Please look at the Places to Stay page for more information.',
    },
    {
        question: 'Do I need to wear a hat?',
        answer: 'Hats, hatinators and fascinators are strongly encouraged but not mandatory. Men do not need to wear a top hat.',
    },
    {
        question: 'What time should I arrive at the ceremony?',
        answer: 'The ceremony will start at 12.30pm promptly. Anyone late will have to wait outside until the ceremony is complete. We advise getting to the church at 12pm where the organist will be playing some tunes and you will have time to catch up with nearest and dearest.',
    },
    {
        question: 'What is the weather like?',
        answer: 'Our wedding is in Manchester which true to form, can often be raining although we are hoping for fair weather on the day.',
    },
    {
        question: 'Am I allowed to bring a plus one?',
        answer: 'Whilst we are very excited to share our joy, we are unable to accommodate any plus ones. Only those named on the invite and with a section to fill in on the RSVP page are invited to our day.',
    },
    {
        question: 'Am I allowed to bring my children?',
        answer: 'Whilst we love little ones, we are not having any children at the wedding beyond those in the bridal party; so as with plus ones, only those named on the invite and with a section to fill in on the RSVP page are invited to our day.',
    },
    {
        question:
            'Can I take photos and videos? Can I post these to social media?',
        answer: 'We will be encouraging you to take lots of photos and videos on the day however we ask that you do not share these on social media until the Bride and Groom have done so first. We also advise that any photography and videography is not permitted during the wedding ceremony and phones need to be off. We have a fantastic photography team that will capture these memories for us, so we ask you to be respectful of our wishes.',
    },
    {
        question: 'How do I pay at the bar?',
        answer: 'The hotel has a very well stocked bar which you can pay for using cash or card. There will be some drinks served on behalf of the happy couple to those attending the Wedding Breakfast.',
    },
    {
        question:
            'Where should we bring or send our wedding gift to, if we have decided to give one?',
        answer: 'You can bring it to the wedding if you would like where there will be a card box for you. Alternatively you can use our PayPal. Please see our registry page for more information about gifts.',
    },
    {
        question: 'I am an evening guest. Can I still attend the ceremony?',
        answer: 'Yes, of course we would love for you to attend our ceremony at the church and then join us later on in the day for a party.',
    },
    {
        question: 'What time will your wedding reception end?',
        answer: 'Our wedding reception finishes at the stroke of midnight. The number for the local taxi company is available on our Places to Stay page. You can pre-book using this company if you would prefer. Please note that the wedding is in Saddleworth where Uber, and similar taxi apps, are not readily available so please plan accordingly.',
    },
    {
        question: 'Will there be other events on the wedding weekend?',
        answer: 'We understand that many of our guests are travelling to attend our wedding and that is also Christmas time so in respect of your time (and bank balances) we are not holding any other events over the weekend other than the day itself.',
    },
    {
        question: 'I have more questions about your wedding, who do I contact?',
        answer: 'We are more than happy to answer any other questions you may have. Please contact either the Bride or Groom with your questions (as long as it is not on the day itself). However if you want a reply in a timely manner you are probably best off contacting the Bride.',
    },
];
