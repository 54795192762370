// import { MockInviteGroup } from '../data/MockInviteData';
import { IInviteGroup } from '../types/databaseTypes';

export const getAllInviteGroups = async (sessionToken?: string) => {
    const response = await fetch(
        `https://dqfc07k4y5.execute-api.eu-west-2.amazonaws.com/Staging/get-all-invite`,
        {
            method: 'POST',
            headers: new Headers({
                Authorization: sessionToken || '',
                'Content-Type': 'application/json',
            }),
        },
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const json = await response.json();

    const scanResults: IInviteGroup[] = [];
    json.response.forEach(function (element: IInviteGroup) {
        scanResults.push(element as IInviteGroup);
    });

    return scanResults;
    // return MockInviteGroup;
};
