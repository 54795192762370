export const AccommodationDetails = [
    {
        id: 1,
        name: 'Premier Inn Rochdale Hotel',
        street: 'Newhey Road',
        city: 'Newhey',
        postcode: 'OL16 3SA',
        hrefWebsite:
            'https://www.premierinn.com/gb/en/hotels/england/greater-manchester/rochdale/rochdale.html',
        hrefLocation:
            'http://maps.google.com/?q=Premier Inn Rochdale hotel, Newhey Road, Rochdale, OL16 3SA',
        distance: '6.6 miles',
        image: 'https://www.premierinn.com/_next/image?url=https%3A%2F%2Fwww.premierinn.com%2Fcontent%2Fdam%2Fpi%2Fwebsites%2Fhotelimages%2Fgb%2Fen%2FR%2FROCTHE%2FROCTHE%202.jpg&w=1080&q=75',
    },
    {
        id: 2,
        name: 'The Diggle Hotel',
        street: 'Station Houses',
        city: 'Diggle',
        postcode: 'OL3 5JZ',
        hrefWebsite: 'https://thedigglehotel.com/',
        hrefLocation:
            'http://maps.google.com/?q=The Diggle Hotel, Station Houses, Diggle, Oldham OL3 5JZ',
        distance: '1.8 miles',
        image: 'https://thedigglehotel.com/wp-content/uploads/2023/04/The-Diggle-Hotel-Pub-2048x1536.jpg',
    },
    {
        id: 3,
        name: 'The White Hart',
        street: 'Stockport Road',
        city: 'Lydgate',
        postcode: 'OL4 4JJ',
        hrefWebsite: 'https://www.thewhitehart.co.uk/',
        hrefLocation:
            'http://maps.google.com/?q=The White Hart Inn at Lydgate, 51 Stockport Rd, Lydgate, Oldham OL4 4JJ',
        distance: '3.9 miles',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/233200810.jpg?k=536a3d9ddbddf4940c0195e199be29e30c0c58ed27a7ae0ae0698c6b48ae77bd&o=&hp=1',
    },
];
