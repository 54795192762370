export const LocationDetails = [
    {
        title: 'Ceremony',
        name: "St Mary's Church",
        street: 'Park Lane',
        city: 'Greenfield',
        postcode: 'OL3 7DX',
        href: "http://maps.google.com/?q=St Mary's Church, Park Lane, Greenfield, OL3 7DX",
        image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/St_Mary%27s_Church%2C_Greenfield.jpg',
    },
    {
        title: 'Reception',
        name: 'The Saddleworth Hotel',
        street: 'Huddersfield Road',
        city: 'Delph',
        postcode: 'OL3 5LX',
        href: 'http://maps.google.com/?q=The Saddleworth Hotel, Huddersfield Road, Delph, OL3 5LX',
        image: 'https://images.trvl-media.com/hotels/8000000/7410000/7402300/7402204/fe89590f_z.jpg',
    },
];
