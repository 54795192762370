import { FC, useState } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FAQData } from '../../data/FAQData';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

interface DisclosurePanel {
    key: number;
    open: boolean;
    close: (
        focusableElement?:
            | HTMLElement
            | React.MutableRefObject<HTMLElement | null>,
    ) => void;
}

export const FAQPage: FC = () => {
    const [activeDisclosurePanel, setActiveDisclosurePanel] =
        useState<DisclosurePanel | null>(null);

    const togglePanels = (newPanel: DisclosurePanel) => {
        if (activeDisclosurePanel) {
            if (
                activeDisclosurePanel.key !== newPanel.key &&
                activeDisclosurePanel.open
            ) {
                activeDisclosurePanel.close();
            }
        }

        setActiveDisclosurePanel({
            ...newPanel,
            open: !newPanel.open,
        });
    };

    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="FAQ"
                image={TitleBannerImageOption.PROPOSE}
                block={true}
            />

            <div className="relative isolate pt-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <div className="mx-auto w-full max-w-4xl p-4 space-y-6">
                        {FAQData.map((faq, index) => (
                            <Disclosure key={index} as="div">
                                {(panel) => {
                                    const { open, close } = panel;
                                    return (
                                        <>
                                            <Disclosure.Button
                                                className="flex flex-col w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-sm sm:text-lg font-text font-medium text-zinc-800 border-2 border-zinc-400 hover:bg-red-50 focus:outline-none focus-visible:ring focus-visible:ring-red-500/75"
                                                onClick={() => {
                                                    if (!open) {
                                                        // On the first click, the panel is opened but the "open" prop's value is still false. Therefore the falsey verification
                                                        // This will make so the panel close itself when we click it while open
                                                        close();
                                                    }
                                                    // Now we call the function to close the other opened panels (if any)
                                                    togglePanels({
                                                        ...panel,
                                                        key: index,
                                                    });
                                                }}
                                            >
                                                <div className="flex flex-row justify-between w-full">
                                                    <p className="text-base sm:text-lg">
                                                        {faq.question}
                                                    </p>
                                                    <ChevronDownIcon
                                                        className={`${
                                                            open
                                                                ? 'rotate-180 transform'
                                                                : ''
                                                        } flex-none ml-2 h-5 w-5 sm:h-7 sm:w-7 text-red-800`}
                                                    />
                                                </div>

                                                <Disclosure.Panel className="pb-2 pt-4 text-sm sm:text-base text-gray-900 text-left font-text whitespace-pre-wrap">
                                                    {faq.answer}
                                                </Disclosure.Panel>
                                            </Disclosure.Button>
                                        </>
                                    );
                                }}
                            </Disclosure>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
