import { CustomIconEnum } from '../types/Enums';

export const ScheduleItems = [
    {
        icon: CustomIconEnum.ARRIVE,
        title: 'Guest Arrival',
        time: '12:00pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.CEREMONY,
        title: 'Ceremony',
        time: '12:30pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.DRINKS_RECEPTION,
        title: 'Drinks Reception',
        time: '1:45pm',
        exclusive: true,
    },
    {
        icon: CustomIconEnum.WEDDING_BREAKFAST,
        title: 'Wedding Breakfast',
        time: '3:15pm',
        exclusive: true,
    },
    {
        icon: CustomIconEnum.SPEECHES,
        title: 'Speeches',
        time: '5:45pm',
        exclusive: true,
    },
    {
        icon: CustomIconEnum.EVENING_RECEPTION,
        title: 'Evening Reception',
        time: '7:30pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.CAKE_CUTTING,
        title: 'Cake Cutting',
        time: '8:00pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.FIRST_DANCE,
        title: 'First Dance',
        time: '8:15pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.PARTY,
        title: 'Party Time',
        time: '8:20pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.EVENING_FOOD,
        title: 'Late Night Bites',
        time: '9:00pm',
        exclusive: false,
    },
    {
        icon: CustomIconEnum.LEAVE,
        title: 'Get in your Carriages',
        time: '12:00pm',
        exclusive: false,
    },
];
