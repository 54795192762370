import { FC, useEffect, useState } from 'react';
import './Timeline.css';
import { StoryDetails } from '../../data/StoryDetails';
import { StoryCard } from '../Cards/StoryCard';
import { CustomIcon } from '../CustomIcon/CustomIcon';
import { CustomIconEnum } from '../../types/Enums';
import { TimelineCard } from '../Cards/TimelineCard';

interface ScheduleTimelineProps {}

export const StoryTimeline: FC<ScheduleTimelineProps> = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    return (
        <div className="timeline">
            {StoryDetails.map((item, index) => (
                <div key={index} className="grid grid-cols-8">
                    {!isMobile &&
                        (index % 2 !== 0 ? (
                            <div className="column-left col-span-3">
                                <TimelineCard opposite={true}>
                                    <StoryCard
                                        title={item.title}
                                        date={item.date}
                                        details={item.details}
                                    />
                                </TimelineCard>
                            </div>
                        ) : (
                            <div className="column-left col-span-3" />
                        ))}
                    <div className="column-center col-span-2">
                        <div className="timeline-line" />
                        <div className="circle">
                            <CustomIcon
                                icon={CustomIconEnum.HEART}
                                color="white"
                            />
                        </div>
                    </div>
                    {isMobile || index % 2 === 0 ? (
                        <div className="column-right col-span-6 md:col-span-3">
                            <TimelineCard opposite={false}>
                                <StoryCard
                                    title={item.title}
                                    date={item.date}
                                    details={item.details}
                                />
                            </TimelineCard>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ))}
            <div className="clearfix"></div>
        </div>
    );
};
