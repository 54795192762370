import { FC } from 'react';
import { WeddingPartyImageOption } from '../../types/Enums';
import Megan from '../../assets/WeddingParty/Megan.jpg';
import John from '../../assets/WeddingParty/John.jpg';
import Francesca from '../../assets/WeddingParty/Franscesca.jpeg';
import David from '../../assets/WeddingParty/David.jpg';
import Erin from '../../assets/WeddingParty/Erin.jpeg';
import Lucy from '../../assets/WeddingParty/Lucy.jpeg';
import Amelia from '../../assets/WeddingParty/Amelia.jpeg';
import Alex from '../../assets/WeddingParty/Alex.jpg';
import Aakash from '../../assets/WeddingParty/Aakash.jpg';
import Jacob from '../../assets/WeddingParty/Jacob.jpg';
import Josh from '../../assets/WeddingParty/Josh.jpg';
import Iona from '../../assets/WeddingParty/Iona.jpeg';
import Evelyn from '../../assets/WeddingParty/Evelyn.jpeg';
import Grayson from '../../assets/WeddingParty/Grayson.jpeg';
import Curley from '../../assets/WeddingParty/Sean&Colleen.jpeg';
import Mercer from '../../assets/WeddingParty/Steve&Nicola.jpg';
import Poppy from '../../assets/WeddingParty/Poppy.jpg';

interface PartyMemberCardProps {
    name: string;
    role: string;
    image: WeddingPartyImageOption;
    wide?: boolean;
}

const getImage = (image: WeddingPartyImageOption) => {
    switch (image) {
        case WeddingPartyImageOption.MEGAN:
            return Megan;
        case WeddingPartyImageOption.JOHN:
            return John;
        case WeddingPartyImageOption.FRANCESCA:
            return Francesca;
        case WeddingPartyImageOption.DAVID:
            return David;
        case WeddingPartyImageOption.ERIN:
            return Erin;
        case WeddingPartyImageOption.LUCY:
            return Lucy;
        case WeddingPartyImageOption.AMELIA:
            return Amelia;
        case WeddingPartyImageOption.ALEX:
            return Alex;
        case WeddingPartyImageOption.AAKASH:
            return Aakash;
        case WeddingPartyImageOption.JACOB:
            return Jacob;
        case WeddingPartyImageOption.JOSH:
            return Josh;
        case WeddingPartyImageOption.IONA:
            return Iona;
        case WeddingPartyImageOption.EVELYN:
            return Evelyn;
        case WeddingPartyImageOption.GRAYSON:
            return Grayson;
        case WeddingPartyImageOption.CURLEY:
            return Curley;
        case WeddingPartyImageOption.MERCER:
            return Mercer;
        case WeddingPartyImageOption.POPPY:
            return Poppy;
    }
    return Aakash;
};

export const PartyMemberCard: FC<PartyMemberCardProps> = ({
    name,
    role,
    image,
    wide,
}) => {
    return (
        <div
            className={`pt-6 pb-6 px-2 sm:max-w-xs ${
                wide ? '' : 'max-w-[10rem]'
            }`}
        >
            <img
                src={getImage(image)}
                className={`object-cover aspect-square rounded-lg ml-auto mr-auto ${
                    wide ? 'max-w-[9rem] sm:max-w-[19rem]' : ''
                }`}
            />
            <p className="mt-2 text-lg sm:text-xl leading-8 text-gray-600 font-semibold font-subtitle">
                {name}
            </p>
            <p className="text-lg sm:text-xl leading-8 text-gray-600 font-text">
                {role}
            </p>
        </div>
    );
};
