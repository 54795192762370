import { FC, useContext, useEffect, useState } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { ScheduleTimeline } from '../../components/Timeline/ScheduleTimeline';
import { LocationCard } from '../../components/Cards/LocationCard';
import { fetchInviteGroup } from '../../api/fetchInviteGroup';
import { AuthContext } from '../../context/AuthContext';
import { isTokenExpired } from '../../lib';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const SchedulePage: FC = () => {
    const auth = useContext(AuthContext);
    const [fullDayGuest, setFullDayGuest] = useState<boolean>();

    const fetchInviteGroupHandler = async () => {
        if (isTokenExpired(auth.session)) {
            setFullDayGuest(false);
        } else {
            const response = await fetchInviteGroup(auth.session);
            setFullDayGuest(response.response.includesBreakfast);
        }
    };

    useEffect(() => {
        if (fullDayGuest == null) fetchInviteGroupHandler();
    }, []);

    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="When  &  Where"
                image={TitleBannerImageOption.VENICE}
            />

            <div className="relative isolate px-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <p className="mt-6 text-3xl leading-8 text-gray-900 sm:text-4xl font-title_cursive font-bold">
                        Saturday 28th December 2024
                    </p>

                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2">
                        <LocationCard locationIndex={0} />
                        <LocationCard locationIndex={1} />
                    </div>

                    <p className="mt-6 text-lg leading-8 text-gray-900 sm:text-xl font-text w-full sm:w-[400px] m-auto">
                        Please check this again closer to the wedding as these
                        times are subject to change
                    </p>

                    <div className="pt-4">
                        <ScheduleTimeline
                            fullDayGuest={fullDayGuest || false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
