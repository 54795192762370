import { FC, useContext, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import './NavBar.css';
import LogoImg from '../../assets/Logo_No_Name-removebg-preview.png';
import { Navigation } from '../../data/NavigationData';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { LoginModes } from '../../types/Enums';

export const NavBar: FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const Logo = () => {
        return (
            <a href={'/home'} className="-m-1.5 p-1.5">
                <span className="sr-only">Logo</span>
                <img
                    className="h-full w-auto"
                    src={LogoImg}
                    alt="Crest Logo"
                    style={{ height: '72px' }}
                />
            </a>
        );
    };

    const handleLogout = () => {
        auth.authService.setSession('');
        navigate('/');
    };

    const adminMode = auth.mode == LoginModes.ADMIN;

    return (
        <div>
            <nav
                className="navbar fixed top-0 z-10 w-full flex items-center justify-between lg:px-8 py-2"
                aria-label="Global"
            >
                <div className="flex lg:shrink-0 px-6 min-w-min">
                    <Logo />
                </div>

                <div className="flex lg:hidden p-6 mx-4">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                {!adminMode && (
                    <div className="hidden lg:flex lg:gap-x-12 p-6 lg:items-center">
                        {Navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-base font-semibold font-subtitle leading-6 text-gray-900"
                            >
                                {item.name}
                            </a>
                        ))}
                        <a
                            href="rsvp"
                            className="rounded-md bg-red-900 px-4 py-2 text-base font-semibold font-subtitle text-white shadow-sm hover:bg-red-800"
                        >
                            RSVP
                        </a>
                    </div>
                )}

                <div className="hidden lg:flex lg:shrink-0 lg:justify-end p-6">
                    <button
                        onClick={handleLogout}
                        className="text-base font-semibold font-subtitle leading-6 text-gray-900"
                    >
                        Log out <span aria-hidden="true">&rarr;</span>
                    </button>
                </div>
            </nav>

            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-2 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Logo />
                        <button
                            type="button"
                            className="-m-2.5 mx-1.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            {!adminMode && (
                                <div className="space-y-2 py-6">
                                    {Navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="mx-4 block rounded-lg px-3 py-2 text-base font-semibold font-subtitle leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            )}
                            {!adminMode && (
                                <div className="py-6">
                                    <a
                                        href="rsvp"
                                        className="text-center inline-block rounded-md bg-red-900 w-1/2 mx-4 px-10 py-2 text-base font-semibold font-subtitle text-white shadow-sm hover:bg-red-800"
                                    >
                                        RSVP
                                    </a>
                                </div>
                            )}
                            <div className="py-3.5">
                                <button
                                    onClick={handleLogout}
                                    className="block rounded-lg px-3 py-2.5 mx-4 text-base font-semibold font-subtitle leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Log out{' '}
                                    <span aria-hidden="true">&rarr;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>
    );
};
