import { FC } from 'react';
import './TitleBanner.css';
import { TitleBannerImageOption } from '../../types/Enums';
import Winter from '../../assets/Backgrounds/istockphoto-1260511128-612x612.jpg';
import Venice from '../../assets/Backgrounds/ll_venezia_50762_001.jpg';
import Bridge from '../../assets/Backgrounds/ll_venezia_50762_006.jpg';
import Door from '../../assets/Backgrounds/ll_venezia_50762_009.jpg';
import Distance from '../../assets/Backgrounds/ll_venezia_50762_222.jpg';
import Twirl from '../../assets/Backgrounds/ll_venezia_50762_056.jpg';
import Stroll from '../../assets/Backgrounds/ll_venezia_50762_077.jpg';
import Wall from '../../assets/Backgrounds/ll_venezia_50762_016.jpg';
import Propose from '../../assets/Backgrounds/ll_venezia_50762_148.jpg';
import Smile from '../../assets/Backgrounds/ll_venezia_50762_003.jpg';

interface TitleBannerProps {
    title: string;
    image: TitleBannerImageOption;
    block?: boolean;
}

const getImage = (image: TitleBannerImageOption) => {
    switch (image) {
        case TitleBannerImageOption.WINTER:
            return Winter;
        case TitleBannerImageOption.VENICE:
            return Venice;
        case TitleBannerImageOption.BRIDGE:
            return Bridge;
        case TitleBannerImageOption.DOOR:
            return Door;
        case TitleBannerImageOption.DISTANCE:
            return Distance;
        case TitleBannerImageOption.TWIRL:
            return Twirl;
        case TitleBannerImageOption.STROLL:
            return Stroll;
        case TitleBannerImageOption.WALL:
            return Wall;
        case TitleBannerImageOption.PROPOSE:
            return Propose;
        case TitleBannerImageOption.SMILE:
            return Smile;
    }
    return Winter;
};

export const TitleBanner: FC<TitleBannerProps> = ({ title, image, block }) => {
    return (
        <div className="relative justify-center top-[88px] lg:top-[112px] .5xl:top-[104px] h-[120px] sm:h-[200px] lg:h-[300px] xl:h-[400px]">
            <div
                className="banner-background w-full h-full flex items-center justify-between"
                style={{ backgroundImage: `url(${getImage(image)})` }}
            >
                <h1
                    className={`data-display overflow-hidden text-5xl py-4 lg:py-8 px-6 font-bold tracking-tight text-gray-900 sm:text-[6rem] lg:text-[8rem] whitespace-pre text-center w-full ${
                        block ? 'font-subtitle' : 'font-title_cursive'
                    }`}
                >
                    {title}
                </h1>
            </div>
        </div>
    );
};
