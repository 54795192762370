import { FC, Fragment, useContext, useState, useEffect } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import {
    NewInviteGroupForm,
    NewInviteGroupFormValues,
} from '../../components/Forms/NewInviteGroupForm';
import { Dialog, Transition } from '@headlessui/react';
import { ViewInviteGroup } from '../../components/Forms/ViewInviteGroup';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { IInviteGroup } from '../../types/databaseTypes';
import { createInviteGroup } from '../../api/createInviteGroup';
import { AuthContext } from '../../context/AuthContext';
import { getAllInviteGroups } from '../../api/getAllInviteGroups';
import { AdminTable } from '../../components/AdminTable/AdminTable';
import { updateInviteGroup } from '../../api/updateInviteGroup';
import { isTokenExpired } from '../../lib';
import { useNavigate } from 'react-router-dom';
import { DownloadFullGuests } from '../../components/Download/DownloadFullGuests';
import { DownloadEveningGuests } from '../../components/Download/DownloadEveningGuests';

export const AdminPage: FC = () => {
    const [newInviteGroupOpen, setNewInviteGroupOpen] = useState(false);
    const [viewInviteGroupOpen, setViewInviteGroupOpen] = useState(false);
    const [viewInviteGroup, setViewInviteGroup] = useState('');
    const [fullGuestCount, setFullGuestCount] = useState<GuestCount>({
        count: 0,
        max: 0,
        yes: 0
    });
    const [partGuestCount, setPartGuestCount] = useState<GuestCount>({
        count: 0,
        max: 0,
        yes: 0
    });
    const [filterAdminTable, setFilterAdminTable] = useState(true); // true = full day guests only, false = evening guests only

    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [inviteGroups, setInviteGroups] = useState<IInviteGroup[]>([]);
    const [filteredInviteGroups, setFilteredInviteGroups] = useState<
        IInviteGroup[]
    >([]);

    const handleAdminLogout = () => {
        auth.authService.setSession('');
        navigate('/login');
    };

    useEffect(() => {
        if (auth.session) refreshInviteGroups();
        filterGuests(filterAdminTable);
    }, []);

    useEffect(() => {
        calculateGuestCount();
        filterGuests(filterAdminTable);
    }, [inviteGroups, filterAdminTable]);

    const refreshInviteGroups = () => {
        if (isTokenExpired(auth.session)) {
            handleAdminLogout();
        } else {
            getAllInviteGroups(auth.session).then((groups) => {
                groups.sort(function (a, b) {
                    if (a.inviteGroupName < b.inviteGroupName) {
                        return -1;
                    }
                    if (a.inviteGroupName > b.inviteGroupName) {
                        return 1;
                    }
                    return 0;
                });
                setInviteGroups(groups);
            });
        }
    };

    const createInviteGroupHandler = async (
        values: NewInviteGroupFormValues,
    ) => {
        const body = {
            inviteGroupName: values.inviteGroupName,
            password: values.password,
            includesBreakfast: values.weddingBreakfast,
        };

        if (isTokenExpired(auth.session)) {
            handleAdminLogout();
        } else {
            const newInviteGroup = await createInviteGroup(body, auth.session);
            console.log(newInviteGroup);
            refreshInviteGroups();
        }
        closeNewInviteGroupModal();
    };

    const updateInviteGroupHandler = async (inviteGroup: IInviteGroup) => {
        if (isTokenExpired(auth.session)) {
            handleAdminLogout();
        } else {
            const newInviteGroup = await updateInviteGroup(
                inviteGroup,
                auth.session,
            );
            console.log(newInviteGroup);
            refreshInviteGroups();
        }
        closeViewInviteGroupModal();
    };

    const calculateGuestCount = () => {
        let fullCount = 0;
        let fullMax = 0;
        let fullYes = 0;
        let partCount = 0;
        let partMax = 0;
        let partYes = 0;

        inviteGroups.forEach((group) => {
            group.invites.forEach((invitee) => {
                if (group.includesBreakfast) {
                    fullMax++;
                    if (invitee.responded) {
                        fullCount++;
                        if (invitee.party.attending) fullYes++;
                    }
                } else {
                    partMax++;
                    if (invitee.responded) {
                        partCount++;
                        if (invitee.party.attending) partYes++;
                    }
                }
            });
        });

        setFullGuestCount({ count: fullCount, max: fullMax, yes: fullYes });
        setPartGuestCount({ count: partCount, max: partMax, yes: partYes });
    };

    const filterGuests = (breakfast: boolean) => {
        setFilteredInviteGroups(
            inviteGroups.filter(
                (inviteGroup) => inviteGroup.includesBreakfast === breakfast,
            ),
        );
    };

    const viewFullDayGuests = () => {
        setFilterAdminTable(true);
    };

    const viewEveningGuests = () => {
        setFilterAdminTable(false);
    };

    const closeNewInviteGroupModal = () => {
        setNewInviteGroupOpen(false);
    };

    const openNewInviteGroupModal = () => {
        setNewInviteGroupOpen(true);
    };

    const closeViewInviteGroupModal = () => {
        setViewInviteGroupOpen(false);
    };

    const openViewInviteGroupModal = (groupID: string) => {
        setViewInviteGroupOpen(true);
        setViewInviteGroup(groupID);
    };

    return (
        <div className="bg-rose-50 min-h-screen h-full font-text">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <div className="relative isolate px-6 pt-1 lg:px-8">
                <div className="mx-auto max-w-2xl py-32">
                    <div className="text-center">
                        <h1 className="text-6xl font-bold font-title_cursive tracking-tight text-gray-900 sm:text-[4rem] whitespace-pre">
                            Admin :
                        </h1>
                        <div className="grid sm:grid-cols-2 grid-cols-1">
                            <div className="mt-6 text-lg font-password leading-8 text-gray-600 flex items-center justify-center gap-2">
                                Full : {fullGuestCount.yes}
                                <span> / </span>
                                {fullGuestCount.count}
                                <span> / </span>
                                {fullGuestCount.max}
                                <DownloadFullGuests inviteData={inviteGroups} />
                            </div>
                            <div className="mt-6 text-lg font-password leading-8 text-gray-600 flex items-center justify-center gap-2">
                                Part : {partGuestCount.yes}
                                <span> / </span>
                                {partGuestCount.count}
                                <span> / </span>
                                {partGuestCount.max}
                                <DownloadEveningGuests
                                    inviteData={inviteGroups}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button
                            type="button"
                            onClick={viewFullDayGuests}
                            className="rounded-md mb-6 bg-red-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Full Day
                        </button>
                        <button
                            type="button"
                            onClick={openNewInviteGroupModal}
                            className="rounded-md mb-6 bg-red-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            New Invite Group
                        </button>
                        <button
                            type="button"
                            onClick={viewEveningGuests}
                            className="rounded-md mb-6 bg-red-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Evening
                        </button>
                    </div>

                    <AdminTable
                        inviteGroups={filteredInviteGroups}
                        openViewInviteGroupModal={openViewInviteGroupModal}
                    />

                    <Transition appear show={newInviteGroupOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            onClose={closeNewInviteGroupModal}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium font-text leading-6 text-gray-900"
                                        >
                                            Create New Invite Group :
                                        </Dialog.Title>

                                        <NewInviteGroupForm
                                            onCancel={closeNewInviteGroupModal}
                                            onCreate={createInviteGroupHandler}
                                        />
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>

                    <Transition appear show={viewInviteGroupOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            onClose={closeViewInviteGroupModal}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 top-24 items-start flex justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="flex flex-col w-full max-w-md max-h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium font-text leading-6 text-gray-900"
                                            >
                                                Invite Group :{' '}
                                                {
                                                    inviteGroups.find(
                                                        (x) =>
                                                            x.id ===
                                                            viewInviteGroup,
                                                    )?.inviteGroupName
                                                }
                                            </Dialog.Title>
                                            <div className="flex items-center justify-end w-16">
                                                <button
                                                    type="button"
                                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                                    onClick={() =>
                                                        closeViewInviteGroupModal()
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        Close menu
                                                    </span>
                                                    <XMarkIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        <ViewInviteGroup
                                            inviteGroup={
                                                inviteGroups.find(
                                                    (x) =>
                                                        x.id ===
                                                        viewInviteGroup,
                                                ) as IInviteGroup
                                            }
                                            onUpdate={updateInviteGroupHandler}
                                        />
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </div>
            </div>
        </div>
    );
};
