import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { ChangeEvent, FC, SyntheticEvent, useContext, useState } from 'react';
import { config } from '../../cognitoconfig';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LoginModes } from '../../types/Enums';

export const LoginPage: FC = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const [user, setUser] = useState({ email: '', password: '' });

    const handleFormInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUser((user) => ({
            ...user,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        const userPool = new CognitoUserPool({
            UserPoolId: config.adminPoolId,
            ClientId: config.adminClientId,
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: user.email,
            Password: user.password,
        });

        const cognitoUser = new CognitoUser({
            Username: user.email,
            Pool: userPool,
        });

        console.log({ user });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                console.log('Authentication successful!', session);
                auth.authService.setMode(LoginModes.ADMIN);
                auth.authService.setSession(session.getIdToken().getJwtToken());
                navigate('/admin-home');
            },
            onFailure: (error) => {
                console.error('Authentication failed:', error);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log(
                    'New password is required:',
                    userAttributes,
                    requiredAttributes,
                );
            },
        });
    };

    return (
        <div className="bg-rose-50 min-h-screen h-full min-w-screen w-full flex items-center justify-center font-text">
            <div className="w-full max-w-xs">
                <form
                    className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={handleSubmit}
                >
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Email"
                            onChange={handleFormInputChange}
                        />
                    </div>
                    <div className="mb-6">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="shadow appearance-none font-password border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            name="password"
                            type="password"
                            placeholder="******************"
                            onChange={handleFormInputChange}
                        />
                    </div>
                    <div className="w-full flex items-center justify-center">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
