import { FC } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
// import { OurStoryDetails } from '../../data/OurStoryDetails';
// import { Chrono } from 'react-chrono';
import { StoryTimeline } from '../../components/Timeline/StoryTimeline';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const OurStoryPage: FC = () => {
    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="Our Story"
                image={TitleBannerImageOption.BRIDGE}
            />

            <div className="relative isolate pr-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <StoryTimeline />
                </div>
            </div>
        </div>
    );
};
