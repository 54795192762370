import { FC } from 'react';
import HeroImage from '../../assets/Backgrounds/ll_venezia_50762_222.jpg';
import './HomePage.css';
import { NavBar } from '../../components/Navbar/NavBar';
import { CountdownTimer } from '../../components/CountdownTimer/CountdownTimer';

export const HomePage: FC = () => {
    return (
        <div
            className="home_page_background"
            style={{ backgroundImage: `url(${HeroImage})` }}
        >
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <div className="faded-pink-background relative isolate h-full">
                <div className="mx-auto max-w-2xl flex flex-col justify-between h-full pb-20 pt-[88px] lg:pt-[112px] .5xl:pt-[104px]">
                    <div className="flex flex-row justify-center h-full items-center">
                        <div className="text-center flex flex-col justify-center">
                            <p className="mt-6 mb-6 text-2xl font-bold leading-8 text-zinc-700 sm:text-3xl 2xl:text-4xl font-title_cursive">
                                The Wedding Celebration of
                            </p>
                            <h1 className="text-5xl font-bold tracking-tight text-zinc-800 min-[400px]:text-6xl sm:text-[4rem] lg:sm:text-[6rem] 2xl:text-[8rem] font-title_cursive whitespace-pre ml-[-5%]">
                                Megan  &  John
                            </h1>
                            <p className="mt-6 text-2xl leading-8 font-bold text-zinc-700 sm:text-3xl 2xl:text-4xl font-subtitle">
                                Saturday 28th December 2024
                            </p>
                            <CountdownTimer targetDate="December 28, 2024 12:30:00" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-x-6">
                        <a
                            href="rsvp"
                            className="rounded-md bg-red-900 px-12 py-5 text-2xl sm:px-20 sm:py-6 2xl:text-3xl font-semibold font-subtitle text-white shadow-sm hover:bg-red-800"
                        >
                            RSVP
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
