import { WeddingPartyImageOption } from '../types/Enums';

export const WeddingPartyDetails = [
    {
        group: 1,
        members: [
            {
                id: 1,
                name: 'Megan Curley',
                role: 'Bride',
                image: WeddingPartyImageOption.MEGAN,
                wide: false,
            },
            {
                id: 2,
                name: 'John Mercer',
                role: 'Groom',
                image: WeddingPartyImageOption.JOHN,
                wide: false,
            },
        ],
    },
    {
        group: 2,
        members: [
            {
                id: 3,
                name: 'Francesca McQuoney',
                role: 'Maid of Honour',
                image: WeddingPartyImageOption.FRANCESCA,
                wide: false,
            },
            {
                id: 4,
                name: 'David Mercer',
                role: 'Best Man',
                image: WeddingPartyImageOption.DAVID,
                wide: false,
            },
        ],
    },
    {
        group: 3,
        members: [
            {
                id: 5,
                name: 'Erin Heywood',
                role: 'Bridesmaid',
                image: WeddingPartyImageOption.ERIN,
                wide: false,
            },
            {
                id: 6,
                name: 'Lucy Thomas',
                role: 'Bridesmaid',
                image: WeddingPartyImageOption.LUCY,
                wide: false,
            },
            {
                id: 7,
                name: 'Amelia Mercer',
                role: 'Bridesmaid',
                image: WeddingPartyImageOption.AMELIA,
                wide: false,
            },
        ],
    },
    {
        group: 4,
        members: [
            {
                id: 8,
                name: 'Alex Chan',
                role: 'Groomsman',
                image: WeddingPartyImageOption.ALEX,
                wide: false,
            },
            {
                id: 9,
                name: 'Aakash Dadhich',
                role: 'Groomsman',
                image: WeddingPartyImageOption.AAKASH,
                wide: false,
            },
            {
                id: 10,
                name: 'Jacob Shaw',
                role: 'Groomsman',
                image: WeddingPartyImageOption.JACOB,
                wide: false,
            },
        ],
    },
    {
        group: 5,
        members: [
            {
                id: 11,
                name: 'Joshua Rabin',
                role: 'Ring Bearer',
                image: WeddingPartyImageOption.JOSH,
                wide: true,
            },
        ],
    },
    {
        group: 6,
        members: [
            {
                id: 12,
                name: 'Iona McQuoney',
                role: 'Flower Girl',
                image: WeddingPartyImageOption.IONA,
                wide: false,
            },
            {
                id: 13,
                name: 'Evelyn Swift',
                role: 'Flower Girl',
                image: WeddingPartyImageOption.EVELYN,
                wide: false,
            },
            {
                id: 14,
                name: 'Grayson Swift',
                role: 'Paige Boy',
                image: WeddingPartyImageOption.GRAYSON,
                wide: false,
            },
        ],
    },
    {
        group: 7,
        members: [
            {
                id: 15,
                name: 'Colleen & Dr Sean Curley',
                role: 'Mother & Father of the Bride',
                image: WeddingPartyImageOption.CURLEY,
                wide: true,
            },
        ],
    },
    {
        group: 8,
        members: [
            {
                id: 17,
                name: 'Steve & Nicola Mercer',
                role: 'Father & Mother of the Groom',
                image: WeddingPartyImageOption.MERCER,
                wide: true,
            },
        ],
    },
    {
        group: 9,
        members: [
            {
                id: 19,
                name: 'Poppy',
                role: 'A Special Guest',
                image: WeddingPartyImageOption.POPPY,
                wide: false,
            },
        ],
    },
];
