import { FC } from 'react';
import { LocationDetails } from '../../data/LocationDetails';

interface LocationCardProps {
    locationIndex: number; // 0 for church, 1 for hotel
}

export const LocationCard: FC<LocationCardProps> = ({ locationIndex }) => {
    return (
        <div className="p-4 bg-white rounded-lg h-min border-2 border-zinc-400">
            <a
                href={LocationDetails[locationIndex].href}
                target="_blank"
                className="flex flex-col items-center"
            >
                <h2 className="pb-4 text-4xl leading-8 text-gray-900 sm:text-4xl font-title_cursive">
                    {LocationDetails[locationIndex].title}
                </h2>
                <img
                    src={LocationDetails[locationIndex].image}
                    className="w-auto h-auto max-w-[300px] max-h-[200px] object-contain rounded-lg"
                />
                <p className="mt-2 text-lg leading-8 text-gray-600 font-semibold font-text">
                    {LocationDetails[locationIndex].name}
                </p>
                <p className="text-lg leading-8 text-blue-800 underline font-text">
                    {LocationDetails[locationIndex].street},{' '}
                    {LocationDetails[locationIndex].city},{' '}
                    {LocationDetails[locationIndex].postcode}
                </p>
            </a>
        </div>
    );
};
