import React, { FC, useEffect, useState } from 'react';
import './Timeline.css';
import { ScheduleItems } from '../../data/ScheduleItems';
import { ScheduleCard } from '../Cards/ScheduleCard';
import { CustomIcon } from '../CustomIcon/CustomIcon';
import { TimelineCard } from '../Cards/TimelineCard';

interface ScheduleTimelineProps {
    fullDayGuest: boolean;
}

export const ScheduleTimeline: FC<ScheduleTimelineProps> = ({
    fullDayGuest,
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    });

    const filteredScheduleItems = fullDayGuest
        ? ScheduleItems
        : ScheduleItems.filter((item) => item.exclusive == false);

    return (
        <div className="timeline">
            {filteredScheduleItems.map((item, index) => (
                <div key={index} className="grid grid-cols-8">
                    {!isMobile &&
                        (index % 2 !== 0 ? (
                            <div className="column-left col-span-3">
                                <TimelineCard opposite={true}>
                                    <ScheduleCard
                                        title={item.title}
                                        time={item.time}
                                    />
                                </TimelineCard>
                            </div>
                        ) : (
                            <div className="column-left col-span-3" />
                        ))}
                    <div className="column-center col-span-2">
                        <div className="timeline-line" />
                        <div className="circle">
                            <CustomIcon icon={item.icon} color="white" />
                        </div>
                    </div>
                    {isMobile || index % 2 === 0 ? (
                        <div className="column-right col-span-6 md:col-span-3">
                            <TimelineCard opposite={false}>
                                <ScheduleCard
                                    title={item.title}
                                    time={item.time}
                                />
                            </TimelineCard>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ))}
            <div className="clearfix"></div>
        </div>
    );
};
