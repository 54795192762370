import { FC } from 'react';
import './CustomIcon.css';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { CustomIconEnum } from '../../types/Enums';
import { ReactComponent as Church } from '../../assets/Icons/church-svgrepo-com.svg';
import { ReactComponent as Rings } from '../../assets/Icons/wedding-rings-svgrepo-com.svg';
import { ReactComponent as Drinks } from '../../assets/Icons/drinks-bottles-svgrepo-com.svg';
import { ReactComponent as Plate } from '../../assets/Icons/food-restaurant-svgrepo-com.svg';
import { ReactComponent as Cheers } from '../../assets/Icons/glass-cheers-solid-svgrepo-com.svg';
import { ReactComponent as Moon } from '../../assets/Icons/moon-svgrepo-com.svg';
import { ReactComponent as Cake } from '../../assets/Icons/wedding-cake-svgrepo-com.svg';
import { ReactComponent as Dance } from '../../assets/Icons/dancing-svgrepo-com.svg';
import { ReactComponent as Party } from '../../assets/Icons/musical-note-svgrepo-com.svg';
import { ReactComponent as Food } from '../../assets/Icons/food-svgrepo-com.svg';
import { ReactComponent as Carriage } from '../../assets/Icons/pumpkin-carriage-2-svgrepo-com.svg';
import { ReactComponent as Heart } from '../../assets/Icons/heart-svgrepo-com.svg';
import { ReactComponent as HeartBreak } from '../../assets/Icons/broken-heart-svgrepo-com.svg';
import { ReactComponent as Download } from '../../assets/Icons/download-minimalistic-svgrepo-com.svg';

interface CustomIconProps {
    icon?: CustomIconEnum;
    color: string;
    className?: string;
    removePadding?: boolean;
}

export const CustomIcon: FC<CustomIconProps> = ({
    icon,
    color,
    className,
    removePadding,
}) => {
    switch (icon) {
        case CustomIconEnum.ARRIVE:
            return (
                <Church
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.CEREMONY:
            return (
                <Rings
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.DRINKS_RECEPTION:
            return (
                <Drinks
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.WEDDING_BREAKFAST:
            return (
                <Plate
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.SPEECHES:
            return (
                <Cheers
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.EVENING_RECEPTION:
            return (
                <Moon
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.CAKE_CUTTING:
            return (
                <Cake
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.FIRST_DANCE:
            return (
                <Dance
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.PARTY:
            return (
                <Party
                    fill={color}
                    className={`${
                        removePadding ? '' : 'small-custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.EVENING_FOOD:
            return (
                <Food
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.LEAVE:
            return (
                <Carriage
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.HEART:
            return (
                <Heart
                    fill={color}
                    stroke={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.HEARTBREAK:
            return (
                <HeartBreak
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
        case CustomIconEnum.DOWNLOAD:
            return (
                <Download
                    fill={color}
                    className={`${
                        removePadding ? '' : 'custom-icon'
                    } ${className}`}
                />
            );
    }
    return (
        <XMarkIcon
            color={color}
            className={`${removePadding ? '' : 'custom-icon'} ${className}`}
        />
    );
};
