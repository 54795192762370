import {
    useState,
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
} from 'react';
import { LoginModes } from '../types/Enums';

interface AuthSession {
    mode?: LoginModes;
    session?: string;
    authService: {
        setMode: (mode: LoginModes) => void;
        setSession: (session: string) => void;
    };
}

export const AuthContext = createContext<AuthSession>({
    authService: {
        setMode: () => {},
        setSession: () => {},
    },
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [mode, setMode] = useState<LoginModes>(
        (sessionStorage.getItem('MeganAndJohnWeddingMode') as LoginModes) ||
            LoginModes.UNAUTHORISED,
    );

    const updateMode = useCallback((mode: LoginModes) => {
        setMode(mode);
        sessionStorage.setItem('MeganAndJohnWeddingMode', mode);
    }, []);

    const [session, setSession] = useState<string | undefined>(
        sessionStorage.getItem('MeganAndJohnWeddingToken') || undefined,
    );

    const updateAdminSession = useCallback((session: string) => {
        setSession(session);
        sessionStorage.setItem('MeganAndJohnWeddingToken', session);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                mode,
                session,
                authService: {
                    setMode: updateMode,
                    setSession: updateAdminSession,
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
