import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import '../Timeline/Timeline.css';

interface TimelineCardProps {
    opposite: boolean; // right true, left false
}

const LeftArrow: FC = () => {
    return (
        <div className="absolute left-full top-[10px]">
            <div className="absolute w-0 h-0 border-l-[15px] border-l-zinc-400 border-t-[15px] border-t-transparent border-b-[15px] border-b-transparent z-10"></div>
        </div>
    );
};

const RightArrow: FC = () => {
    return (
        <div className="absolute -left-[15px] top-[10px]">
            <div className="absolute w-0 h-0 border-r-[15px] border-r-zinc-400 border-t-[15px] border-t-transparent border-b-[15px] border-b-transparent z-10"></div>
        </div>
    );
};

export const TimelineCard: FC<PropsWithChildren<TimelineCardProps>> = ({
    children,
    opposite,
}) => {
    const observerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // Loop over the entries
            entries.forEach((entry) => {
                // If the element is visible
                if (entry.isIntersecting) {
                    console.log(entry.target);
                    // Add the animation class
                    entry.target.classList.add('fade-entrance');
                }
            });
        });

        if (observerRef.current) observer.observe(observerRef.current);
    }, [observerRef]);

    return (
        <div
            className="relative bg-white rounded-lg my-4 px-4 opacity-0 border-2 border-zinc-400"
            ref={observerRef}
        >
            {opposite ? <LeftArrow /> : <RightArrow />}

            {children}
        </div>
    );
};
