import { FC, useEffect, useRef } from 'react';
import { IInviteGroupPayload } from '../../types/databaseTypes';
import '../Forms/RSVPForm/RSVPForm.css';

interface RSVPDetailsCardProps {
    inviteGroup: IInviteGroupPayload | undefined;
    inviteName: string;
}

export const RSVPDetailsCard: FC<RSVPDetailsCardProps> = ({
    inviteGroup,
    inviteName,
}) => {
    const observerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // Loop over the entries
            entries.forEach((entry) => {
                // If the element is visible
                if (entry.isIntersecting) {
                    console.log(entry.target);
                    // Add the animation class
                    entry.target.classList.add('fade-entrance');
                }
            });
        });

        if (observerRef.current) observer.observe(observerRef.current);
    }, [observerRef]);

    const invite = inviteGroup?.invites.find((invite) => {
        return invite.name === inviteName;
    });

    return (
        <div
            className="space-y-12 mb-6 opacity-0 bg-christmas bg-cover rounded-2xl"
            ref={observerRef}
        >
            <div className="border-2 rounded-2xl border-zinc-400 mt-12 mb-12 p-6 faded-background">
                <h2 className="text-3xl sm:text-4xl font-semibold font-title_cursive leading-7 text-gray-900">
                    {invite ? invite?.name : 'Name'}
                </h2>

                <div className="mt-4 space-y-4 font-text text-base sm:text-lg">
                    <p>Thank you for responding.</p>
                    <div className="">
                        {invite?.ceremony.attending
                            ? 'We look forward to sharing our day with you!'
                            : 'Your presence shall be missed.'}
                    </div>
                </div>
            </div>
        </div>
    );
};
